<template>
  <AppPageCard>
    <template #card-title> School Away Application </template>
    <template #card-toolbar>
      <!-- begin: b-nav tabs  -->
      <b-nav pills class="nav-light-primary font-weight-bolder">
        <b-nav-item to="teacher" exact exact-active-class="active"
          >Teacher
        </b-nav-item>
        <b-nav-item to="staff" exact exact-active-class="active"
          >Staff
        </b-nav-item>
      </b-nav>
      <!-- end: b-nav tabs  -->

      <!-- begin: refresh button  -->
      <b-button
        variant="primary"
        class="btn btn-icon btn-light btn-hover-primary ml-4"
        v-on:click="refresh"
      >
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </b-button>
      <!-- end: refresh button  -->
    </template>
    <!-- begin: router view  -->
    <div class="mt-5">
      <router-view></router-view>
    </div>
    <!-- end: router view  -->
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "SchoolEmployeeAwayApplicationPage",
  components: { AppPageCard },
  data() {
    return {
      type: 1,
    };
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },
  methods: {
    refresh() {
      if (this.type === 1) {
        this.$store.dispatch("fetchTeacherAwayApplicationApprovalList");
      } else if (this.type === 2) {
        this.$store.dispatch("fetchStaffAwayApplicationApprovalList");
      }
    },
    checkRoute(name) {
      switch (name) {
        case "schoolAwayApplication":
          this.type = 1;
          break;
        case "staffAwayApplication":
          this.type = 2;
          break;
        default:
          this.type = 1;
          break;
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
